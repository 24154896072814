import React, { useState } from "react"
import * as Yup from "yup"
import { Formik } from "formik"
import { toast } from "react-toastify"
import { formatDate } from "../helper/date"
import axios from "axios"
import { getStrapiUrl } from "../helper/uri"
import DatepickerField from "../components/datepicker-field/datepicker-field"
import RcFormErrors from "../page-components/helper/rcFormErrors"
import * as styles from "../pages-parts/betriebsmarkt/betriebsmarkt-contact.module.scss"
import SEO from "../page-components/seo"
import Layout from "../page-components/layout"
import RcDefaultPageTeaser from "../page-components/teaser/rcDefaultPageTeaser"

const GewinnspielPage = () => {


  const [ sent, setSent ] = useState(false);

  const formSchema = Yup.object().shape(
    {
      mail: Yup.string().email("Ungültige Mail-Adresse").required("E-Mail Adresse notwendig"),
      vorname: Yup.string().required("Ihr Vorname ist notwendig"),
      nachname: Yup.string().required("Ihr Nachname ist notwendig"),
      adresse: Yup.string().required("Ihre Adresse ist notwendig"),
      postleitzahl: Yup.string().required("Ihre Postleitzahl ist notwendig"),
      stadt: Yup.string().required("Ihre Stadt ist notwendig"),
    }
  );

  return <Layout>

    <RcDefaultPageTeaser>
      <h1>Gewinnspiel für den 1st Entrepreneurship & Business Management Summit</h1>

    </RcDefaultPageTeaser>

    <SEO title={"Gewinnspiel für den 1st 1st Entrepreneurship & Business Management Summit"} />


    <div className="container bg-white h-100 pt-3">

    {
      sent &&
      <div  className={"my-4"}>
        <h3>Teilnahme erfolgreich</h3>
        <p>Vielen Dank für die Teilnahme - der Gewinner wird kontaktiert.</p>
      </div>
    }

    {!sent &&

      <Formik
        validationSchema={formSchema}
        initialValues={
          {
            mail: '',
            vorname: '',
            nachname: '',
            adresse: '',
            postleitzahl: '',
            stadt: '',
          }
        }
        onSubmit={async (values, { setSubmitting }) => {

          try {
            await axios.post(getStrapiUrl() + "/submit-contact/gewinnspiel", { titel: "1st Entrepreneurship & Business Management Summit",  ...values });
            setSubmitting(false);
            setSent(true);
          } catch (err) {
            toast("Es ist ein unbekannter Fehler passiert.");
          }

        }}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
          <form className={"rc-form row mb-5"} onSubmit={handleSubmit}>

            <div className={"col-12 col-lg-8"}>

              <div className="rc-form-control">
                <label>Vorname*</label>

                <input
                  type="text"
                  name="vorname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.vorname}
                />

              </div>

              <div className="rc-form-control">
                <label>Nachname*</label>

                <input
                  type="text"
                  name="nachname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nachname}
                />

              </div>

              <div className="rc-form-control">
                <label>E-Mail*</label>

                <input
                  type="text"
                  name="mail"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mail}
                />

              </div>

              <div className="rc-form-control">
                <label>Adresse*</label>

                <input
                  type="text"
                  name="adresse"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.adresse}
                />

              </div>

              <div className="rc-form-control">
                <label>Postleitzahl*</label>

                <input
                  type="text"
                  name="postleitzahl"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.postleitzahl}
                />

              </div>

              <div className="rc-form-control">
                <label>Stadt*</label>

                <input
                  type="text"
                  name="stadt"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.stadt}
                />

              </div>

              <RcFormErrors touched={touched} errors={errors} className={"mb-4"} />
              <button type="submit" disabled={isSubmitting}>Absenden</button>


              <div className={styles.appendix + " mt-5"}>
                *) Sämtliche uns übermittelten Informationen und Daten werden diskret und anonym behandelt. Sie werden
                auch nicht weitergegeben oder längerfristig gespeichert.
              </div>


            </div>

          </form>
        )}


      </Formik>
    }

    </div>

  </Layout>;

};

export default GewinnspielPage;
